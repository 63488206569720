.div_size {
    width: 180px;
    height: 270px;
  }
  #layer_card {
    width: 70px;
    height: 70px;
    background-color: #ccc;
    border-radius: 20px !important;
    border: 2px solid white !important;
    position: absolute;
    z-index: 2;
    bottom: 0;
    margin:10px;
    margin-bottom: 6px;
  }
  #layer_card:hover {
    transform: scale(1.1);
  }
  .sub_layer_card {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 10px !important;
    border: 2px solid white;
    z-index: 1;
    margin:20px;
  }
  .sub_layer_card:hover {
    transform: scale(1.1);
  }
  .sub_position_1 {
    position: absolute;
    bottom: 70px;
  }
  .sub_position_2 {
    position: inherit;
    bottom: 130px;
  }
  .sub_position_3 {
    position: inherit;
    bottom: 190px;
  }
  .image_card {
    height: 100%;
    border-radius: 20px !important;
    background: white !important;
  }
  .sub_image_card {
    height: 100%;
    border-radius: 10px !important;
  }
  .sl_1-enter-active,
  .sl_1-leave-active,
  .sl_2-enter-active,
  .sl_2-leave-active,
  .sl_3-enter-active,
  .sl_3-leave-active {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-delay: 0s;
  }
  .sl_1-enter,
  .sl_1-leave-to,
  .sl_2-enter,
  .sl_2-leave-to,
  .sl_3-enter,
  .sl_3-leave-to {
    transform: translateX(100px);
    opacity: 0;
  }

  .labelOutline {
    text-shadow: 0 0 2px black, 0 0 2px black, 0 0 2px black, 0 0 2px black; 
    color: white;
  }