
@keyframes eB8lyM6F9GT3_tr__tr {
  0% {
    transform: translate(255.983284px, 255.964565px) rotate(180deg);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1)
  }

  88.235294% {
    transform: translate(255.983284px, 255.964565px) rotate(360deg)
  }

  100% {
    transform: translate(255.983284px, 255.964565px) rotate(360deg)
  }
}